//TODO: Change later

.search .controls-container {
  position: absolute;
  left: 50px;
  top: 0;
}

#geocoder * {
  background-color: #232323;
  color: white;
  border: none;
  outline: none;
}
