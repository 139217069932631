@import '../../../../assets/scss/abstracts/variables';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  background-color: $main-color;
  padding: 0 20px;

  @media (min-width: $breakpoint-xxl) {
    padding: 0 2vw;
  }
}

.header-list-item {
  cursor: pointer;
  border-left: 1px solid $border-color;
  padding: 0 12px;

  @media (min-width: $breakpoint-xxl) {
    padding: 0 16px;
  }

  &:last-child {
    border-right: 1px solid $border-color;
  }

  &.active {
    border-bottom: 2px solid $theme-color;
  }
}

.header-list-icon.icon {
  width: 17px;
  height: 20px;
  object-fit: contain;
  display: block;
  margin-right: 8px;
}

.header-list-text {
  display: block;
  font-size: 14px;
  color: $secondary-color;

  @media (min-width: $breakpoint-xxl) {
    font-size: 16px;
  }

  .header-list-item:hover &,
  .header-list-item.active & {
    color: $theme-color;
  }
}

.header-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.header-btn-icon.icon {
  width: 21px;
  display: block;
  margin: 10px;
}

.header-user {
  margin-left: 10px;

  @media (min-width: $breakpoint-xl) {
    margin-left: 22px;
  }
}

.header-user-icon.icon {
  width: 20px;
  margin-right: 8px;
  display: block;
}

.header-dropdown {
  margin: 0 15px;
  padding: 4px 11px;
  border: 1px solid #6c6c6c;
  border-radius: $border-radius;

  @media (min-width: $breakpoint-xxl) {
    margin: 0 5px;
    min-width: 150px;
  }

  .dropdown-btn {
    width: 100%;
    justify-content: space-between;
  }
}

.header-logout.icon {
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.header-logo-tactile {
  max-height: 28px;
  margin-right: 15px;

  @media (min-width: $breakpoint-xxl) {
    margin-right: 61px;
  }
}

.header-logo-img {
  max-height: 37px;
  margin-left: 15px;
  min-width: 65px;
  object-fit: contain;

  @media (min-width: $breakpoint-xxl) {
    margin-left: 61px;
  }
}

.header-center {
  margin: 0 15px;

  @media (min-width: $breakpoint-xxl) {
    margin: 0 auto;
  }
}
