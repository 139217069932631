@import '/../frontend/src/assets/scss/abstracts/variables';

.dashboard {
  position: fixed;
  top: 116px;
  right: 101px;
  z-index: 2;
  min-width: 20vw;
  display: block;

  &.isOpen {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &.isClosed {
    -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
}

.dashboard-body {
  background-color: $items-color;
  padding: 22px 37px 12px 37px;
  border-radius: $border-radius;
}

.dashboard-body-title {
  font-size: 18px;
  font-weight: 300;
  color: #fefefe;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
}

.dashboard-progress {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 21px;
}

.dashboard-progress-annotation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  color: #fefefe;
}

.dashboard-arrow-icon.icon {
  position: absolute;
  width: 30px;
  height: 54px;
  background-size: 10px;
  transform: rotate(0deg);
  top: 8px;
  left: 8px;
  cursor: pointer;
}

.dashboard-road {
  margin-top: 1px;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
