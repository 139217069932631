$border-color: rgba(238, 238, 238, 0.1);
$main-color: #1d1d1d;
$primary-color: #ffffff;
$secondary-color: #999999;
$theme-color: #fd7500;
$danger-color: #ff0000;
$items-color: #262626;

$border-radius: 5px;

$box-shadow: 0 0.2em 2em -0.5em rgba(0, 0, 0, 0.35);

$colors-map: (
  main: $main-color,
  primary: $primary-color,
  secondary: $secondary-color,
  danger: $danger-color,
  selected: $theme-color,
);

$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1920px;

$breakpoints: (
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1920px,
);
