@import '/../frontend/src//assets/scss/abstracts/variables';

.filter {
  border-bottom: 4px solid transparent;
  background-color: $items-color;
  padding: 16px 18px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.filter-title {
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-clear-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 18px;

  &:focus {
    outline: none;
  }
}

.filter-clear-icon.icon {
  width: 14px;
  height: 14px;
}

.filter-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 9px;
}

.filter-btn {
  &:enabled:hover {
    cursor: pointer;
    border-color: $theme-color;
  }

  &:disabled {
    opacity: 0.5;
  }
}
