@import '../../../assets/scss/abstracts/variables';

.stilized {
  position: relative;
  display: flex;
  width: 93px;
  height: 33px;
  border-radius: $border-radius;
  border: 1px solid #6c6c6c;
  background-color: $main-color;
  cursor: pointer;
  overflow: hidden;
  margin-right: 28px;
  margin-left: 9px;

  @media (min-width: $breakpoint-xxl) {
    width: 123px;
  }
}

.stilized-input {
  display: block;
  height: 100%;
  width: 29px;
  cursor: pointer;

  @media (min-width: $breakpoint-xxl) {
    width: 41px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    display: block;
    width: 31px;
    height: 29px;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $main-color;

    @media (min-width: $breakpoint-xxl) {
      width: 41px;
      height: 31px;
      top: 0;
    }
  }

  &:first-of-type {
    &:hover {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 512 512' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M256 0C114.516 0 0 114.497 0 256c0 141.483 114.497 256 256 256 141.483 0 256-114.497 256-256C512 114.517 397.503 0 256 0zm0 482C131.098 482 30 380.922 30 256 30 131.098 131.078 30 256 30c124.902 0 226 101.078 226 226 0 124.902-101.078 226-226 226z'/%3E%3Cpath fill='%23fff' d='M256 100c-86.3 0-156 70.234-156 156 0 85.496 69.43 156 156 156 86.422 0 156-70.333 156-156 0-85.698-69.656-156-156-156zm15 30.897c34.992 4.169 65.629 22.73 85.8 49.586l-61.607 35.569c-6.664-6.539-14.95-11.421-24.193-13.995v-71.16zm-30-.001v71.16c-9.242 2.573-17.529 7.456-24.193 13.995L155.2 180.483c20.171-26.856 50.808-45.417 85.8-49.587zM140.163 305.559C133.627 290.339 130 273.586 130 256s3.627-34.339 10.163-49.558l61.606 35.569C200.615 246.483 200 251.171 200 256s.615 9.517 1.769 13.99l-61.606 35.569zM241 381.103c-34.992-4.169-65.629-22.73-85.8-49.586l61.607-35.569c6.664 6.539 14.95 11.422 24.193 13.995v71.16zM230 256c0-14.337 11.664-26 26-26 14.337 0 26 11.663 26 26s-11.663 26-26 26c-14.336 0-26-11.663-26-26zm41 125.104v-71.16c9.243-2.573 17.529-7.456 24.193-13.995l61.607 35.569c-20.171 26.855-50.808 45.416-85.8 49.586zm39.231-111.114c1.155-4.473 1.769-9.161 1.769-13.99 0-4.829-.615-9.517-1.769-13.99l61.606-35.568C378.373 221.661 382 238.414 382 256s-3.627 34.339-10.163 49.558l-61.606-35.568z'/%3E%3C/svg%3E%0A");
      }
    }
    &::before {
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 512 512' viewBox='0 0 512 512'%3E%3Cpath fill='%236c6c6c' d='M256 0C114.516 0 0 114.497 0 256c0 141.483 114.497 256 256 256 141.483 0 256-114.497 256-256C512 114.517 397.503 0 256 0zm0 482C131.098 482 30 380.922 30 256 30 131.098 131.078 30 256 30c124.902 0 226 101.078 226 226 0 124.902-101.078 226-226 226z'/%3E%3Cpath fill='%236c6c6c' d='M256 100c-86.3 0-156 70.234-156 156 0 85.496 69.43 156 156 156 86.422 0 156-70.333 156-156 0-85.698-69.656-156-156-156zm15 30.897c34.992 4.169 65.629 22.73 85.8 49.586l-61.607 35.569c-6.664-6.539-14.95-11.421-24.193-13.995v-71.16zm-30-.001v71.16c-9.242 2.573-17.529 7.456-24.193 13.995L155.2 180.483c20.171-26.856 50.808-45.417 85.8-49.587zM140.163 305.559C133.627 290.339 130 273.586 130 256s3.627-34.339 10.163-49.558l61.606 35.569C200.615 246.483 200 251.171 200 256s.615 9.517 1.769 13.99l-61.606 35.569zM241 381.103c-34.992-4.169-65.629-22.73-85.8-49.586l61.607-35.569c6.664 6.539 14.95 11.422 24.193 13.995v71.16zM230 256c0-14.337 11.664-26 26-26 14.337 0 26 11.663 26 26s-11.663 26-26 26c-14.336 0-26-11.663-26-26zm41 125.104v-71.16c9.243-2.573 17.529-7.456 24.193-13.995l61.607 35.569c-20.171 26.855-50.808 45.416-85.8 49.586zm39.231-111.114c1.155-4.473 1.769-9.161 1.769-13.99 0-4.829-.615-9.517-1.769-13.99l61.606-35.568C378.373 221.661 382 238.414 382 256s-3.627 34.339-10.163 49.558l-61.606-35.568z'/%3E%3C/svg%3E%0A");
    }
  }

  &:nth-of-type(2) {
    &:hover {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M60.026 512c-1.109 0-2.234-.093-3.365-.285-10.89-1.846-18.222-12.17-16.377-23.061L120.283 16.66c1.846-10.889 12.166-18.222 23.06-16.376 10.89 1.846 18.222 12.17 16.377 23.061L79.721 495.339C78.067 505.098 69.604 512 60.026 512zm395.313-.285c10.89-1.846 18.222-12.17 16.377-23.061L391.717 16.661c-1.846-10.89-12.175-18.222-23.06-16.376-10.89 1.846-18.222 12.17-16.377 23.061l79.999 471.994c1.654 9.76 10.117 16.661 19.695 16.661 1.109-.001 2.235-.094 3.365-.286zM276 100.002V20.003c0-11.046-8.954-20-20-20s-20 8.954-20 20v79.999c0 11.046 8.954 20 20 20s20-8.954 20-20zM276 296v-80c0-11.046-8.954-20-20-20s-20 8.954-20 20v80c0 11.046 8.954 20 20 20s20-8.954 20-20zm0 195.997v-79.999c0-11.046-8.954-20-20-20s-20 8.954-20 20v79.999c0 11.046 8.954 20 20 20s20-8.954 20-20z'/%3E%3C/svg%3E%0A");
      }
    }

    &::before {
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath fill='%236c6c6c' d='M60.026 512c-1.109 0-2.234-.093-3.365-.285-10.89-1.846-18.222-12.17-16.377-23.061L120.283 16.66c1.846-10.889 12.166-18.222 23.06-16.376 10.89 1.846 18.222 12.17 16.377 23.061L79.721 495.339C78.067 505.098 69.604 512 60.026 512zm395.313-.285c10.89-1.846 18.222-12.17 16.377-23.061L391.717 16.661c-1.846-10.89-12.175-18.222-23.06-16.376-10.89 1.846-18.222 12.17-16.377 23.061l79.999 471.994c1.654 9.76 10.117 16.661 19.695 16.661 1.109-.001 2.235-.094 3.365-.286zM276 100.002V20.003c0-11.046-8.954-20-20-20s-20 8.954-20 20v79.999c0 11.046 8.954 20 20 20s20-8.954 20-20zM276 296v-80c0-11.046-8.954-20-20-20s-20 8.954-20 20v80c0 11.046 8.954 20 20 20s20-8.954 20-20zm0 195.997v-79.999c0-11.046-8.954-20-20-20s-20 8.954-20 20v79.999c0 11.046 8.954 20 20 20s20-8.954 20-20z'/%3E%3C/svg%3E%0A");
    }
  }
  &:last-of-type {
    &:hover {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 511.999 511.999' viewBox='0 0 511.999 511.999'%3E%3Cpath fill='%23fff' d='M504.251 399.55L305.495 55.296C295.164 37.402 276.66 26.72 255.999 26.72s-39.165 10.683-49.496 28.576L7.748 399.55c-10.331 17.894-10.331 39.259 0 57.153 10.331 17.893 28.834 28.576 49.496 28.576h397.511c20.661 0 39.165-10.683 49.496-28.576 10.33-17.894 10.33-39.259 0-57.153zm-26 42.141c-4.905 8.494-13.688 13.565-23.496 13.565H57.244c-9.808 0-18.592-5.071-23.496-13.565s-4.904-18.637 0-27.131L232.503 70.306c4.905-8.494 13.688-13.565 23.496-13.565 9.808 0 18.592 5.071 23.496 13.565L478.251 414.56c4.904 8.494 4.904 18.637 0 27.131z'/%3E%3Cpath fill='%23fff' d='M255.999 355.183c-11.036 0-20.015 8.979-20.015 20.015 0 11.036 8.979 20.015 20.015 20.015 11.036 0 20.015-8.979 20.015-20.015 0-11.036-8.979-20.015-20.015-20.015zM240.987 170.044H271.009V325.159H240.987z'/%3E%3C/svg%3E%0A");
      }
    }

    &::before {
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 511.999 511.999' viewBox='0 0 511.999 511.999'%3E%3Cpath fill='%236c6c6c' d='M504.251 399.55L305.495 55.296C295.164 37.402 276.66 26.72 255.999 26.72s-39.165 10.683-49.496 28.576L7.748 399.55c-10.331 17.894-10.331 39.259 0 57.153 10.331 17.893 28.834 28.576 49.496 28.576h397.511c20.661 0 39.165-10.683 49.496-28.576 10.33-17.894 10.33-39.259 0-57.153zm-26 42.141c-4.905 8.494-13.688 13.565-23.496 13.565H57.244c-9.808 0-18.592-5.071-23.496-13.565s-4.904-18.637 0-27.131L232.503 70.306c4.905-8.494 13.688-13.565 23.496-13.565 9.808 0 18.592 5.071 23.496 13.565L478.251 414.56c4.904 8.494 4.904 18.637 0 27.131z'/%3E%3Cpath fill='%236c6c6c' d='M255.999 355.183c-11.036 0-20.015 8.979-20.015 20.015 0 11.036 8.979 20.015 20.015 20.015 11.036 0 20.015-8.979 20.015-20.015 0-11.036-8.979-20.015-20.015-20.015zM240.987 170.044H271.009V325.159H240.987z'/%3E%3C/svg%3E%0A");
    }
  }
}

.stilized-btn {
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 3;
  width: 29px;
  height: 29px;
  border-radius: $border-radius;
  transition: all 0.2s ease;
  background-color: $theme-color;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  transform: translateX(60px);

  @media (min-width: $breakpoint-xxl) {
    transform: translate(80px);
    width: 39px;
  }
  .stilized-input:first-of-type:checked ~ & {
    transform: translateX(0px);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 512 512' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M256 0C114.516 0 0 114.497 0 256c0 141.483 114.497 256 256 256 141.483 0 256-114.497 256-256C512 114.517 397.503 0 256 0zm0 482C131.098 482 30 380.922 30 256 30 131.098 131.078 30 256 30c124.902 0 226 101.078 226 226 0 124.902-101.078 226-226 226z'/%3E%3Cpath fill='%23fff' d='M256 100c-86.3 0-156 70.234-156 156 0 85.496 69.43 156 156 156 86.422 0 156-70.333 156-156 0-85.698-69.656-156-156-156zm15 30.897c34.992 4.169 65.629 22.73 85.8 49.586l-61.607 35.569c-6.664-6.539-14.95-11.421-24.193-13.995v-71.16zm-30-.001v71.16c-9.242 2.573-17.529 7.456-24.193 13.995L155.2 180.483c20.171-26.856 50.808-45.417 85.8-49.587zM140.163 305.559C133.627 290.339 130 273.586 130 256s3.627-34.339 10.163-49.558l61.606 35.569C200.615 246.483 200 251.171 200 256s.615 9.517 1.769 13.99l-61.606 35.569zM241 381.103c-34.992-4.169-65.629-22.73-85.8-49.586l61.607-35.569c6.664 6.539 14.95 11.422 24.193 13.995v71.16zM230 256c0-14.337 11.664-26 26-26 14.337 0 26 11.663 26 26s-11.663 26-26 26c-14.336 0-26-11.663-26-26zm41 125.104v-71.16c9.243-2.573 17.529-7.456 24.193-13.995l61.607 35.569c-20.171 26.855-50.808 45.416-85.8 49.586zm39.231-111.114c1.155-4.473 1.769-9.161 1.769-13.99 0-4.829-.615-9.517-1.769-13.99l61.606-35.568C378.373 221.661 382 238.414 382 256s-3.627 34.339-10.163 49.558l-61.606-35.568z'/%3E%3C/svg%3E%0A");
  }

  .stilized-input:nth-of-type(2):checked ~ & {
    transform: translateX(29px);
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M60.026 512c-1.109 0-2.234-.093-3.365-.285-10.89-1.846-18.222-12.17-16.377-23.061L120.283 16.66c1.846-10.889 12.166-18.222 23.06-16.376 10.89 1.846 18.222 12.17 16.377 23.061L79.721 495.339C78.067 505.098 69.604 512 60.026 512zm395.313-.285c10.89-1.846 18.222-12.17 16.377-23.061L391.717 16.661c-1.846-10.89-12.175-18.222-23.06-16.376-10.89 1.846-18.222 12.17-16.377 23.061l79.999 471.994c1.654 9.76 10.117 16.661 19.695 16.661 1.109-.001 2.235-.094 3.365-.286zM276 100.002V20.003c0-11.046-8.954-20-20-20s-20 8.954-20 20v79.999c0 11.046 8.954 20 20 20s20-8.954 20-20zM276 296v-80c0-11.046-8.954-20-20-20s-20 8.954-20 20v80c0 11.046 8.954 20 20 20s20-8.954 20-20zm0 195.997v-79.999c0-11.046-8.954-20-20-20s-20 8.954-20 20v79.999c0 11.046 8.954 20 20 20s20-8.954 20-20z'/%3E%3C/svg%3E%0A");

    @media (min-width: $breakpoint-xxl) {
      transform: translate(40px);
    }
  }
  .stilized-input:last-of-type ~ & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 511.999 511.999' viewBox='0 0 511.999 511.999'%3E%3Cpath fill='%23fff' d='M504.251 399.55L305.495 55.296C295.164 37.402 276.66 26.72 255.999 26.72s-39.165 10.683-49.496 28.576L7.748 399.55c-10.331 17.894-10.331 39.259 0 57.153 10.331 17.893 28.834 28.576 49.496 28.576h397.511c20.661 0 39.165-10.683 49.496-28.576 10.33-17.894 10.33-39.259 0-57.153zm-26 42.141c-4.905 8.494-13.688 13.565-23.496 13.565H57.244c-9.808 0-18.592-5.071-23.496-13.565s-4.904-18.637 0-27.131L232.503 70.306c4.905-8.494 13.688-13.565 23.496-13.565 9.808 0 18.592 5.071 23.496 13.565L478.251 414.56c4.904 8.494 4.904 18.637 0 27.131z'/%3E%3Cpath fill='%23fff' d='M255.999 355.183c-11.036 0-20.015 8.979-20.015 20.015 0 11.036 8.979 20.015 20.015 20.015 11.036 0 20.015-8.979 20.015-20.015 0-11.036-8.979-20.015-20.015-20.015zM240.987 170.044H271.009V325.159H240.987z'/%3E%3C/svg%3E%0A");
  }
}
