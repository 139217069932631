@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-ExtraBold.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-ExtraBold.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-ExtraLight.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-ExtraLight.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-Black.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-Black.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-Light.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-Light.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-Bold.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-Bold.woff') format('woff'), url('../../fonts/Heebo/Heebo-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-SemiBold.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-SemiBold.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-Regular.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-Regular.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-Medium.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-Medium.woff') format('woff'),
    url('../../fonts/Heebo/Heebo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Heebo';
  src: url('../../fonts/Heebo/Heebo-Thin.woff2') format('woff2'),
    url('../../fonts/Heebo/Heebo-Thin.woff') format('woff'), url('../../fonts/Heebo/Heebo-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
