@import '/../frontend/src/assets/scss/abstracts/variables';

.map-layers {
  border-bottom: 4px solid transparent;
  background-color: $items-color;
  padding: 16px 18px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.map-layers-title {
  font-size: 12px;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.map-layers-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.map-layers-btn {
  &:enabled:hover {
    cursor: pointer;
    border-color: $theme-color;
  }

  &:disabled {
    opacity: 0.5;
    border-color: $primary-color;
    color: $primary-color;
  }
}

.map-layers-toggler {
  .toggler-text {
    font-size: 12px;
  }
}
