@import '../../../../assets/scss/abstracts/variables';

.login-page {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../../assets/img/login-back.png');
  background-size: cover;
  background-position: center;
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: $primary-color;
  margin-bottom: 30px;
}

.login {
  background-color: $main-color;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 555px;
  border-radius: 20px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-form {
  flex: 1;
  padding: 65px 61px 58px 61px;
}

.login-icon.icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.login-input {
  display: block;
  margin-top: 12px;
  margin-bottom: 16px;
  position: relative;

  &:first-of-type {
    margin-bottom: 25px;
  }

  .form-field {
    display: flex;
    align-items: center;
    padding: 6px 0;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #6f706d;
  }

  .form-control {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    color: $primary-color;
    font-size: 16px;

    &::placeholder {
      color: $primary-color;
      font-size: 16px;
    }
  }

  .form-label {
    display: none;
  }

  .form-validation {
    color: #ff0000;
    font-size: 12px;
    position: absolute;
    left: 0;
    top: 100%;
  }
}

.login-credential {
  font-size: 16px;
  color: $primary-color;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 37px;
  cursor: pointer;

  &::before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
    width: 19px;
    height: 19px;
    border: 1px solid #6f706d;
    border-radius: 4px;
  }

  &.is-checked {
    &::before {
      content: '\2713';
      color: #6f706d;
      background-color: $primary-color;
    }
  }
}

.login-btn {
  display: block;
  padding: 10px 20px;
  margin: 0 auto;
  margin-top: 50px;
  cursor: pointer;
  background-color: #ff7500;
  color: $primary-color;
  font-size: 18px;
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s ease-in-out;
  min-width: 146px;
  min-height: 47px;
  border-radius: 20px;

  &:hover {
    background-color: darken(#ff7500, 5);
  }
}

.logo-powered {
  position: absolute;
  top: 79px;
  left: 149px;
}

.logo-powered-img {
  height: 47px;
}
