*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*:active,
*:focus {
  outline: none;
}

html,
body {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  font-family: 'Heebo', sans-serif;
  font-weight: 300;
  scrollbar-width: thin;
  scrollbar-color: $secondary-color transparent;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color;
    border-radius: $border-radius;
    height: 35%;
  }
}
