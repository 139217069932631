.dropdown {
  position: relative;

  &.isDisabled {
    opacity: 0.5;
  }
}

.dropdown-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  .dropdown.isDisabled & {
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.dropdown-btn-icon {
  width: 11px;
  height: 11px;
  display: block;
  margin-left: 9px;

  .dropdown.active & {
    transform: rotate(270deg);
  }
}

.dropdown-text {
  font-size: 16px;
  color: $primary-color;
}

.dropdown-group {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 3;
  width: 100%;
  margin-top: 10px;
  max-height: 230px;
  min-width: 200px;
  overflow-y: auto;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &.active {
    display: block;
  }
}

.dropdown-item {
  padding: 16px;
  cursor: pointer;
  position: relative;
  color: $primary-color;
  background-color: #282828;

  &:hover {
    background-color: #3e3e3e;
  }
}
